<template>
  <div class="col">
    <!-- Large Screen -->
    <server-table
      class="hidden-sm-only hidden-xs-only"
      :count="familiesCount"
      :DataItems="families"
      @Refresh="Refresh"
      @reset="filter = {}"
      :filter="filter"
      :title="'FamiliesCount'"
      :action="false"
    >
      <template #columns>
        <el-table-column prop="name" label="family name"></el-table-column>
        <el-table-column
          prop="admin_email"
          :label="`${localization('Email')}`"
          min-width="120px"
        >
        </el-table-column>
        <el-table-column
          prop="total_members"
          label="total members"
        ></el-table-column>
        <el-table-column
          prop="remaining_seats"
          label="remaining members"
          min-width="120px"
        ></el-table-column>
        <el-table-column prop="expiration_date" label="expiration date">
          <template slot-scope="scope">
            <div>
              {{ toLocalDatetime(scope.row.exp_time, "en") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="family_code" label="code"></el-table-column>
        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="actions_wrapper td-actions">
              <router-link
                :to="`/families/${scope.row.id}`"
                v-tooltip.top-center="`${localization('Show Family')}`"
                class="btn btn-info btn-link btn-xs"
              >
                <i class="fa fa-eye"></i
              ></router-link>
              <a
                v-tooltip.top-center="`Edit Family`"
                class="btn btn-warning btn-link btn-xs"
                @click="openEditDialog(scope.row)"
              >
                <i class="fa fa-edit"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </template>
    </server-table>
    <!-- Small Screen -->
    <server-table
      class="hidden-md-only hidden-lg-only hidden-xl-only"
      :count="familiesCount"
      :DataItems="families"
      @Refresh="Refresh"
      @reset="filter = {}"
      :filter="filter"
      :title="'FamiliesCount'"
      :action="false"
    >
      <template #columns>
        <el-table-column prop="name" label="family name"></el-table-column>

        <el-table-column prop="family_code" label="code"></el-table-column>
        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="actions_wrapper td-actions">
              <router-link
                :to="`/families/${scope.row.id}`"
                v-tooltip.top-center="`${localization('Show Family')}`"
                class="btn btn-info btn-link btn-xs"
              >
                <i class="fa fa-eye"></i
              ></router-link>
              <a
                v-tooltip.top-center="`Edit Family`"
                class="btn btn-warning btn-link btn-xs"
                @click="openEditDialog(scope.row)"
              >
                <i class="fa fa-edit"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </template>
    </server-table>

    <!-- Start Edit Family -->
    <el-dialog
      title="Edit Family"
      :visible.sync="edit_family_dialog"
      width="50%"
      top="4vh"
      :class="{ 'text-right ': isRtl() }"
    >
      <el-form
        :model="familyData"
        ref="familyRef"
        :rules="familyRules"
        label-position="top"
      >
        <div class="row">
          <div class="col-md-6">
            <el-form-item :label="`${localization('Name')}`" prop="name">
              <el-input v-model="familyData.name"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item label="Family Code" prop="family_code">
              <el-input v-model="familyData.family_code"></el-input>
            </el-form-item>
          </div>
        </div>
        <div
          class="
                  dialog-footer
                  dialog_footer
                "
        >
          <el-button
            class="text-center text-capitalize save_button delete_button"
            @click="edit_family_dialog = false"
          >
            {{ localization("Cancel") }}
          </el-button>
          <el-button
            type=""
            class="
                    text-center
                    text-capitalize
                    save_button
                  "
            @click="updateFamily"
          >
            Save
          </el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- End Edit Family -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      filter: {},
      edit_family_dialog: false,
      familyData: {
        name: null,
        family_code: null
      },
      family_id: null,
      familyRules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        family_code: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    this.Refresh();
  },
  methods: {
    Refresh(query) {
      this.$store.dispatch("user/handlerFamilies", {
        query: query
      });
    },
    openEditDialog(item) {
      this.edit_family_dialog = true;
      this.family_id = item.id;
      this.familyData.name = item.name;
      this.familyData.family_code = item.family_code;
    },
    updateFamily() {
      this.$refs["familyRef"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/updateFamilies", {
              id: this.family_id,
              query: this.familyData
            })
            .then(_ => {
              this.edit_family_dialog = false;
              this.Refresh();
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
          //
        } else {
          return false;
        }
      });
    }
  },
  computed: {
    families() {
      return this.$store.getters["user/getFamilies"];
    },
    familiesCount() {
      return this.$store.getters["user/getFamiliesCount"];
    }
  },
  components: {
    ServerTable
  }
};
</script>

<style lang="scss" scoped>
.actions_wrapper {
  // display: flex;
  // gap: 5px;
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;

  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
  .save_button {
    // background-color: #23ccef;
    border-color: #00563f;
    background-color: #00563f;
    min-width: 150px;
    color: #fff;
    @media (min-width: 320px) and (max-width: 767px) {
      min-width: 124px;
    }
    &.delete_button {
      background-color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
</style>
